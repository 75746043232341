
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "finance-dashboard",
  components: {},
  data() {
    return {
      numberOfUnsettledCreditNotes: 0 as number,
      totalCommission: 0 as number,
      totalPremiumPaid: 0 as number,
      totalUnsettledAmount: 0 as number,
      totalVat: 0 as number,
      creditNotes: [
        {
          narration: "",
          clientName: "",
          serialNumber: "",
          policyNumber: "",
          ibnPolicyNumber: "",
          businessType: "",
          amount: 0,
          premium: 0,
          status: "",
          transactionDate: "",
          policyPublicId: "",
          publicId: "",
        },
      ],
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      capitalize,
    } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageTitle("Finance Dashboard");
    });

    return {
      useReusableNavigation,
      reinitialization,
      capitalize,
    };
  },
  created() {
    this.getDashboardSummary();

    //Set page title
    document.title = "Finance Dashboard | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getDashboardSummary() {
      //Fetch underwriter user dashboard data
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_DASHBOARD_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.numberOfUnsettledCreditNotes =
              data.data.numberOfUnsettledCreditNotes;
            this.totalCommission = data.data.totalCommission;
            this.totalPremiumPaid = data.data.totalPremiumPaid;
            this.totalUnsettledAmount = data.data.totalUnsettledAmount;
            this.totalVat = data.data.totalVat;
            this.creditNotes = data.data.creditNotes;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
